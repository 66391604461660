import axios from '@/core/axios';

import { CollectionList } from '../collections/collections.types';
import { AppSectionCode } from '../app-sections/app-sections.types';
import { Device, DevicesListOptions, ImportUpdateStatusResponse } from './devices.types';

export class DevicesService {
  list(opts: DevicesListOptions = {}): Promise<CollectionList<'devices'>> {
    const { filters, pagination } = opts;
    return axios
      .get<CollectionList<'devices'>>('/devices', { params: { ...filters, ...pagination } })
      .then(resp => resp?.data);
  }

  get(serialNo: string): Promise<Device> {
    return axios.get<Device>(`/devices/${serialNo}`).then(resp => resp?.data);
  }

  findBySerialNo(serialNo: string, params: { stocks?: AppSectionCode[] } = {}): Promise<Device> {
    return axios.get<Device>(`/devices/find/${serialNo}`, { params }).then(resp => resp?.data);
  }

  newDevice(): Promise<Device> {
    return axios.get<Device>(`devices/new`).then(resp => resp?.data);
  }

  create(device: Device): Promise<Device> {
    return axios.post<Device>(`devices`, { device }).then(resp => resp?.data);
  }

  createFromControl(controlId: number, device: Device): Promise<Device> {
    return axios.post<Device>(`/devices/${controlId}`, { device }).then(resp => resp?.data);
  }

  update(deviceId: number | string, device: Partial<Device>): Promise<Device> {
    // deviceId should be serialNo in fact
    if (!deviceId) throw new Error('Missing device id in update');
    return axios.put<Device>(`/devices/${deviceId}`, { device }).then(resp => resp?.data);
  }

  delete(serialNo: string): Promise<void> {
    if (!serialNo) throw new Error('Missing serial number in delete');
    return axios.delete<void>(`/devices/${serialNo}`).then(resp => resp?.data);
  }

  move(serialNo: string, stockId: number, reason: string): Promise<Device> {
    return axios.post<Device>(`/devices/${serialNo}/stocks/${stockId}`, { reason }).then(resp => resp?.data);
  }

  getLabel(serialNo: string): Promise<string> {
    return axios.get(`/devices/${serialNo}/control-label`).then(response => response.data.zpl);
  }

  getMiniLabel(serialNo: string): Promise<string> {
    return axios.get(`/devices/${serialNo}/mini-label`).then(response => response.data.zpl);
  }

  lost(payload: any): Promise<any> {
    return axios.post('/devices/lost', payload).then(resp => resp?.data);
  }

  deviceType(device: Device): 'laptop' | 'iMac' {
    return device.model_namestring === 'iMac' ? 'iMac' : 'laptop';
  }

  importUpdateStatus(file: File): Promise<ImportUpdateStatusResponse> {
    const formData = new FormData();
    formData.append('file', file);
    return axios
      .post<ImportUpdateStatusResponse>('/devices/import', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(resp => resp?.data);
  }

  async exist(serialNo: string): Promise<boolean> {
    let exist = true;
    try {
      await axios.get<Device>(`/devices/${serialNo}`);
    } catch (err) {
      exist = false;
    }
    return exist;
  }
}

export const devicesService = new DevicesService();
